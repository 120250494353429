import React from "react"
import { Link } from "gatsby"

import Icon from "../../Icon"

import "./index.scss"

const Footer = () => (
  <footer>
    <div className="container">
      <div className="row">
        <div className="col">
          <p>Coastline Newspaper Group</p>
          <a
            href="https://www.facebook.com/coastlinenewspapers/"
            target="_blank"
            rel="noreferrer"
            className="facebook-link"
          >
            <Icon prefix="fab" name="facebook-square" />
          </a>
          <p>
            Many of the photographs courtesy of Tourism and Events Queensland
          </p>
          <nav className="nav justify-content-center">
            <Link to="/terms-of-use" className="nav-link">
              Terms of Use
            </Link>
            <Link to="/privacy-policy" className="nav-link">
              Privacy Policy
            </Link>
          </nav>
          <span>Site by </span>
          <a
            href="https://www.dssdigital.net/?utm_source=website&utm_medium=tag&utm_campaign=coastline_news"
            target="_blank"
            rel="noopener noreferrer"
          >
            DSS Digital
          </a>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
