import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"

import {
  faSpinner,
  faChevronRight,
  faHome,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons"

import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons"

library.add(
  faSpinner,
  faChevronRight,
  faHome,
  faArrowCircleRight,
  faFacebookSquare
)

const Icon = ({ prefix, name, spin }) => {
  if (spin === true) {
    return <FontAwesomeIcon icon={[prefix, name]} spin />
  } else {
    return <FontAwesomeIcon icon={[prefix, name]} />
  }
}

export default Icon
