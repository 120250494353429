import React from "react"
import { Link } from "gatsby"
import Navbar from "react-bootstrap/Navbar"
import logo from "../../../images/logo.png"

import "./index.scss"

const Navi = (props) => {
  const activeItem = (path) => {
    if (props.location.pathname === path) {
      return "nav-item active"
    } else {
      return "nav-item"
    }
  }

  return (
    <Navbar expand="lg" fixed="top" bg="light-trans">
      <div className="container">
        <Navbar.Brand href="#home">
          <img alt="" src={logo} className="d-inline-block align-top" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="main-nav">
          <ul className="navbar-nav nav-fill">
            <li className={activeItem("/")}>
              <Link to="/" className="nav-link">
                Home
              </Link>
            </li>
            <li className={activeItem("/about")}>
              <Link to="/about" className="nav-link">
                About
              </Link>
            </li>
            <li className={activeItem("/tourist-destinations")}>
              <Link to="/tourist-destinations" className="nav-link">
                QLD Tourist Destinations
              </Link>
            </li>
            <li className={activeItem("/contact")}>
              <Link to="/contact" className="nav-link">
                Contact Us
              </Link>
            </li>
          </ul>
        </Navbar.Collapse>
      </div>
    </Navbar>
  )
}

export default Navi
